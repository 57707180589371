<template>
  <div style="background:#fff" id='LY'>
    <div class="search-content">
      <a-form :model="searchForm" layout="inline">
        <a-form-item label="" style="width: 15%">
          <a-input v-model:value="searchForm.orderId" style="width:100%" placeholder="订单号" allowClear />
        </a-form-item>
        <a-form-item label="" style="width: 13%">
          <a-input v-model:value="searchForm.customerName" style="width:100%" placeholder="客户名称" allowClear />
        </a-form-item>
        <a-form-item label="" style="width: 13%">
          <a-input v-model:value="searchForm.dockingPerson" style="width:50%" placeholder="对接人" allowClear />
          <a-select v-model:value="searchForm.isConfirm" style="width:50%" placeholder="是否确认" allowClear>
            <a-select-option :value="1">是</a-select-option>
            <a-select-option :value="0">否</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="" style="width: 13%">
          <a-input v-model:value="searchForm.vinNo" style="width:100%" placeholder="车牌号" allowClear />
        </a-form-item>
        <a-form-item label="" style="width: 13%">
          <a-input v-model:value="searchForm.salesman" style="width:50%" placeholder="业务/分单员" />
          <a-select v-model:value="searchForm.orgId" style="width:50%" placeholder="部门" allowClear>
            <a-select-option v-for="item in $store.state.app.orgBusList" :key="item.id" :value="item.id">{{ item.name
            }}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="" style="width: 13%">
          <a-cascader v-model:value="startCitys" style="width:100%" :options="$store.state.app.cityDict" :show-search="{ filter }" :changeOnSelect="true" @change="setStartAddressMethod" placeholder="起运地" allowClear />
        </a-form-item>
        <a-form-item label="" style="width: 13%">
          <a-cascader v-model:value="endCitys" style="width:100%" :options="$store.state.app.cityDict" :show-search="{ filter }" :changeOnSelect="true" @change="setEndAddressMethod" placeholder="目的地" allowClear />
        </a-form-item>
        <a-form-item label="" style="width: 7%">
          <a-date-picker v-model:value="searchForm.pushStartTime" style="width:100%" @change="startTimeChange" placeholder="开始时间" format="YYYY-MM-DD" allowClear />
        </a-form-item>
        <a-form-item label="" style="width: 7%">
          <a-date-picker v-model:value="searchForm.pushEndTime" style="width:100%" @change="endTimeChange" placeholder="结束时间" format="YYYY-MM-DD" allowClear />
        </a-form-item>
        <a-form-item label="" style="width: 13%">
          <a-select v-model:value="searchForm.settlementStatus" style="width:50%" placeholder="结算状态" allowClear>
            <a-select-option value="">全部</a-select-option>
            <a-select-option :value="2">已结算</a-select-option>
            <a-select-option :value="1">部分结算</a-select-option>
            <a-select-option :value="0">未结算</a-select-option>
          </a-select>
          <a-select v-model:value="searchForm.isFollow" style="width:50%" :options="$store.state.enumAll.DocumentaryEnum" placeholder="跟单状态" allowClear>
          </a-select>
        </a-form-item>
        <a-form-item label="" style="width: 13%">
          <a-input v-model:value="searchForm.otherMobile" style="width:50%" placeholder="电话号码" allowClear />
          <a-select v-model:value="searchForm.isRefund" style="width:50%" placeholder="返款状态" allowClear>
            <a-select-option :value="1">是</a-select-option>
            <a-select-option :value="0">否</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="" style="width: 13%;display:flex">
          <a-select v-model:value="searchForm.refundSettlementStatus" style="width:50%" placeholder="返款结算" allowClear>
            <a-select-option :value="2">已结算</a-select-option>
            <a-select-option :value="0">未结算</a-select-option>
          </a-select>
          <a-select v-model:value="searchForm.orderStatus" style="width:50%" :options="statusList" placeholder="订单状态" allowClear>
          </a-select>
        </a-form-item>
        <a-form-item style="width: 13%">
          <a-select allowClear style="width:50%" v-model:value="searchForm.orderSettlement" :options="orderSettlementList" placeholder="结算方式">
          </a-select>
          <a-select v-model:value="searchForm.isMiniOrder" style="width:50%" placeholder="小程序下单" allowClear>
            <a-select-option :value="1">是</a-select-option>
            <a-select-option :value="0">否</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="" style="width: 13%">
          <div class="operate-btn">
            <a-space>
              <a-button @click="searchData" type="primary" :loading="loading">搜索</a-button>
              <a-button class="header-reset-btn" @click="resetData" :loading="loading">重置</a-button>
              <a-button type="dashed" @click="queryVehicleInTransitShow(0)">在途</a-button>
              <a-button type="dashed" @click="queryVehicleInTransitShow(1)">发车计划</a-button>
            </a-space>
          </div>
        </a-form-item>
      </a-form>
    </div>
    <div class="list-content-div">
      <div class="list-data" v-for="item in listData" :key="item.orderId">
        <div class="list-header">
          <div class="order-title">
            <a-typography-paragraph :copyable="{ text: item.orderId }">
              <span>订单号：{{ item.orderId }}</span>
            </a-typography-paragraph>
            <a-tag v-if="item.isFollow && item.isFollow?.value !== 9" style="margin-left:20px" :color="item.isFollow?.value === 1 ? '#87d068' : '#f50'" @click="followBtn(item)">{{ item.isFollow?.label
              }}</a-tag>
            <a-tag v-show="item.isFollow?.value === 9" style="margin-left:20px" color="#ccc">{{ item.isFollow?.label
            }}</a-tag>
            <StarFilled v-if="item.isFocus?.value === 0" class="cur-p" @click="addFollowNew(item,1)" style="font-size:24px;color:#999" />
            <StarFilled v-if="item.isFocus?.value === 1" class="cur-p" @click="addFollowNew(item,0)" style="font-size:24px;color:#0066CC" />
            <!-- <div v-show="item.orderStatus?.value !== 7">
              <div v-show="item.show">
                <a-tag v-show="item.isFollow?.value === 1" style="margin-left:20px" color="#87d068"
                  @click="followBtn(item)">已读</a-tag>
                <a-tag v-show="item.isFollow?.value === 0 || !item.isFollow" style="margin-left:20px" color="#f50"
                  @click="followBtn(item)">未读</a-tag>
              </div>
            </div> -->
          </div>
          <div class="data-title">
            <span style="margin-right:20px" v-show="item.contractAmt">合同总金额：{{ item.contractAmt }}</span>
            <div style="margin-right:20px;display:flex;align-items:center">
              <img src="@/assets/images/salesman.png" style="width:20px;height:20px" />
              业务员：{{ item.salesman }}&emsp;
              <span v-show="item.sourceMan">分单员-{{ item.sourceMan }}</span>
              {{ item.orderTime }}
            </div>
            <div v-show="item.pushTime" style="margin-right:20px;display:flex;align-items:center">
              <img src="@/assets/images/order-time.png" style="width:20px;height:20px" />
              推送时间：{{ item.pushTime }}
            </div>
            <div style="display:flex;align-items:center">
              <img src="@/assets/images/company.png" style="width:20px;height:20px" />
              承运公司： {{ item.carrierType?.label }}
            </div>
          </div>
        </div>
        <div class="body-content">
          <div class="detail-data">
            <div class="detail">
              <div><span class="detail-data-title">客户名称：</span>
                <a @click="customerId = item.customerId, detailShow = true,lookName(item)">{{ item.customerName }}</a>
              </div>
              <div><span class="detail-data-title">对接人：</span>{{ item.businessContactPerson || '' }}</div>
            </div>
            <div class="detail">
              <div><span class="detail-data-title">起运地：</span>{{ item.startAddress }}</div>
              <div><span class="detail-data-title">目的地：</span>{{ item.endAddress }}</div>
            </div>
            <div class="detail">
              <div><span class="detail-data-title">结算方式：</span>{{ item.orderSettlement?.label }}</div>
              <div :style="{ color: item.settlementStatus?.value === 0 ? '#f00' : '' }">
                <span class="detail-data-title">结算状态：</span>{{ item.settlementStatus?.label }}
              </div>
            </div>
            <div class="detail">
              <div :style="{ color: (item.orderStatus.value === 0) ? '#ff9900' : (item.orderStatus.value === 1) ? '#339933' : (item.orderStatus.value === 2) ? '#36a3e7' : (item.orderStatus.value === 9) ? '#19be6b' : '' }">
                <span class="detail-data-title">订单状态：</span>{{ item.orderStatus?.label }}
                <span>{{ item.isConfirm?.value === 1 ? '(已确认)' : '(未确认)' }}</span>
              </div>
              <div><span class="detail-data-title">订单台量：</span>{{ item.vehicleNum }}</div>
            </div>
            <div class="detail">
              <div><span class="detail-data-title">特殊需求：</span>
                <span v-show="item.isNoticeReleaseCar?.value === 1">等通知放车</span>
                <span v-show="item.isDeliveryCard?.value === 1">交车条</span>
                <span v-show="item.isInspectionPay?.value === 1">验车打款</span>
                <span  v-show="item.isNoticeSalesman?.value === 1">通知业务员</span>
              </div>
              <div><span class="detail-data-title">返款状态：</span>{{ item.isRefund?.label }}
                <a-tag :style="{ color: (item.refundSettlementStatus?.value === 2) ? '#000' :  '#f00'}" v-if="item.isRefund?.value === 1">{{ item.refundSettlementStatus?.label }}</a-tag>
              </div>
            </div>
          </div>
          <div class="table-data">
            <a-table size="small" :columns="[sortColumn, ...columns]" :rowKey="(record, index) => { return index }" :data-source="item.vehicleList" bordered :pagination="false" :loading="loading">
              <template #sort="{ index }">
                {{ index + 1 }}
              </template>
              <template #brand="{ record }">
                <div>
                  <div style="margin-right:10px">{{ record.vinNo }}</div>
                  <span>{{ record.brand + record.model }}</span>
                </div>
              </template>
              <template #transStatus="{ record }">
                <div>
                  <span>{{ record.transportType?.label + '：' }}</span>
                  <span>{{ record.transStatus?.label }}</span>
                </div>
              </template>
              <template #isDelivery="{ record }">
                <span v-if="record.isDelivery.value === 0">{{ record.isDelivery?.label }}</span>
                <a @click="lookDelivery(record)" v-else>{{ record.isDelivery?.label }}</a>
              </template>
              <template #nowCity="{ record }">
                <div>
                  <div>{{ record.nowCity }}</div>
                  <div>{{ record.gpsTime }}</div>
                </div>
              </template>
              <template #documentaryDetails="{ record }">
                <div>
                  <a-popover title="详细信息">
                    <template #content>
                      <p style="width:100px;padding: 0 10px;">{{ record.documentaryTime }}</p>
                      <p style="width:400px;padding: 0 10px;">{{ record.documentaryDetails }}</p>
                    </template>
                    <div style=display:flex>
                      <div v-show="record.documentaryTime" style="overflow:hidden;white-space:nowrap;text-overflow: ellipsis;">{{
                          record.documentaryTime + '：'
                        }}</div>
                      <div style="width:600px;overflow:hidden;white-space:nowrap;text-overflow: ellipsis;">{{
                        record.documentaryDetails }}
                      </div>
                    </div>
                  </a-popover>
                </div>
              </template>
              <template #documentaryType="{ record }">
                <div>
                  <span v-show="record.documentaryType?.value === 1" style="color:#6B778C">{{
                    record.documentaryType?.label
                  }}</span>
                  <span v-show="record.documentaryType?.value === 2" style="color:#F59A23">{{
                    record.documentaryType?.label
                  }}</span>
                  <span v-show="record.documentaryType?.value === 3" style="color:#D9001B">{{
                    record.documentaryType?.label
                  }}</span>
                </div>
              </template>
              <template #operate="{ record }">
                <div>
                  <a-space>
                    <a @click="followOrder(record)">跟单</a>
                    <a @click="openPayQRC(record)">支付码</a>
                    <a @click="onLog(record)">日志</a>
                    <a @click="checkOut(record.orderVehicleId)">运输</a>
                    <a @click="orderVehicleGps(record.orderVehicleId)">位置</a>
                    <a @click="lookVehImg(record.orderVehicleId, record.vinNo)">图片</a>
                    <a @click="lookPlan(record)">方案</a>
                    <a v-show="record.exceptionNum && record.exceptionNum > 0" @click="lookException(record)">{{ '异常' +
                      record.exceptionNum }}</a>
                    <a v-show="record.isPlan > 0" @click="inspectionCommissionClick(record.orderVehicleId)">委托</a>
                  </a-space>
                </div>
              </template>
            </a-table>
          </div>
          <div class="operate-div">
            <a-space>
              <a-button type="primary" @click="routerDetail(item)">订单详情</a-button>
              <a-button type="primary" @click="openPayQRC(item)" v-if="item.orderStatus.value === 1 || item.orderStatus.value === 2 || item.orderStatus.value === 9">
                订单支付码
              </a-button>
              <a-button type="primary" @click="QRCshow(item)">小程序二维码</a-button>
              <a-button type="primary" @click="lookOrderSettle(item)">订单结算信息</a-button>
              <a-button type="primary" @click="evaluationLook(item)">订单评论信息</a-button>
              <a-button type="primary" @click="lookProfit(item), profitShow = true">利润</a-button>
              <a-button type="primary">
                <a-popconfirm title="确定复制该订单，确定后会生成新订单，适用场景是往返订单的情况。 " @confirm="copyOrderById(item.orderId)">
                  <a>复制</a>
                </a-popconfirm>
              </a-button>
              <a-button type="primary" v-show="item.insurance === 1" @click="seeInsurance(item.orderId)">保险</a-button>
              <a-button type="primary" @click="showRelatedApplet(item.orderId)">关联小程序</a-button>
              <a-button type="primary" v-show="item.isBind?.value === 1" @click="showVirtualNumber(item.orderId)">订单虚拟号</a-button>
              <a-button type="primary" v-show="isTrue(item.orderStatus.value)&&item.vehicleList.length>1" @click="splitOrders(item)">拆分订单</a-button>
            </a-space>
          </div>
        </div>
      </div>
      <a-pagination size="small" :total="pagination.total" :pageSize="pagination.pageSize" :pageSizeOptions="['5', '10', '15', '20']" :current="pagination.current" show-size-changer show-quick-jumper @showSizeChange="onShowSizeChange" @change="paginationChange" />
    </div>
  </div>
  <a-drawer title="详情" width="80%" placement="right" v-model:visible="detailShow" destroyOnClose @close="closeDrawer">
    <CustomerDetail v-model:customerId="customerId" :type="showType" @ok="closeDrawer" @updatePage="loadData" />
  </a-drawer>
  <!-- 跟单 -->
  <a-modal v-model:visible="followShow" width="60%" footer="" :bodyStyle="{ padding: '5px' }" @cancel="closeWindow">
    <template #title>
      <div>
        <h3>跟单-{{ vehicleDetail.vinNo }}</h3>
      </div>
    </template>
    <div>
      <div class="follow-order-operate">
        <div style="background-color: #F2F2F2;padding:10px;border:1px solid #ccc;">
          <a-form :model="followForm" layout="inline">
            <a-form-item label="跟单类型">
              <a-select v-model:value="followForm.documentaryType" @change="followTypeChange" style="width: 120px" placeholder="请选择">
                <a-select-option :value="1">普通跟进</a-select-option>
                <a-select-option :value="2">时效异常</a-select-option>
                <a-select-option :value="3">质损跟进</a-select-option>
                <a-select-option :value="4">目的地异常</a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item v-if="followForm.documentaryType !== null && followForm.documentaryType !== 1" :label="titleLabel + '时间'">
              <a-date-picker style="width:120px" @change="documentaryTimeChange" />
            </a-form-item>
            <!-- <a-form-item v-show="" label="质损时间">
              <a-month-picker style="width:120px" v-model:value="followForm.massLossDate" @change="montChange" show-time
                placeholder="月份选择" />
            </a-form-item> -->
          </a-form>
          <div v-show="followForm.documentaryType !== null">
            <a-textarea v-model:value="followForm.content" :placeholder="placeholder" :rows="4" :maxlength="200" :showCount="true" />
          </div>
          <div v-show="followForm.documentaryType !== null && followForm.documentaryType !== 1" style="margin-top:15px;">
            <a-upload style="flex:1" v-model:file-list="imgFileList" accept="image/*" list-type="picture" :multiple="true" :remove="handleRemove" :before-upload="beforeUpload">
              <a-button :loading="followLoading" style="margin-right:10px;">
                <CameraOutlined />
                上传{{ titleLabel }}照片
              </a-button>
            </a-upload>
            <a-button v-show="imgFileList.length > 0" :loading="followLoading" @click="saveImgs">保存图片</a-button>
          </div>
          <div>
            <div class="operate-btn" style="text-align:right;margin-top:15px">
              <a-button class="header-search-btn" @click="confirmFollow" :loading="followLoading">确定</a-button>
              <a-button class="header-reset-btn" @click="resetFollow" :loading="followLoading">重置</a-button>
            </div>
          </div>
        </div>
        <a-divider />
        <ResultFollow ref="endFollowRef" />
      </div>
    </div>
  </a-modal>
  <!-- 支付码 -->
  <a-modal v-model:visible="QRC" title="二维码" footer="" @cancel="QRCcontext = '', QRC = false">
    <div class="QRC" v-show="status === 1 && QRCcontext === ''">
      <a-spin v-show="QRCloading" tip="Loading..." />
      <div v-show="!QRCloading" style="text-align:center;margin-bottom:10px;width:100%">
        <div>起：{{ detailData.startAddress }}</div>
        <div>终：{{ detailData.endAddress }}</div>
        <div style="display:flex;justify-content: space-around;">
          <div>车架号：<a-tag color="green" v-for="(item, index) in detailData.vehicleList" :key="index">{{ item.vinNo
          }}</a-tag>
          </div>
        </div>
      </div>
      <div style="display:flex;justify-content: center;">
        <QrcodeVue id="QRCpayImg" v-show="!QRCloading" :value="payUrl" :size="200" level="H" />
      </div>
    </div>
    <div v-show="QRCcontext !== ''" style="text-align: center;">{{ QRCcontext }}</div>
  </a-modal>
  <!-- 评论信息 -->
  <a-drawer v-model:visible="evaluationShow" title="订单评价" footer='' width="50%">
    <div v-if="remarkTime === null || remarkTime === '' || remarkTime === undefined">
      <h1>暂无评论信息</h1>
    </div>
    <div v-else class="evaluation-data">
      <div class="left-evaluation">
        <div class="title-data">
          <div>评价时间: {{ remarkTime }}</div>
        </div>
        <!-- 评论内容 -->
        <div v-for="item in evaluationList" :key="item.no">
          <div>{{ item.no }}. {{ item.title }}</div>
          <div style="color:#1890FF">
            <span v-if="item.option.value === 2">A</span> <span v-if="item.option.value === 1">B</span> <span v-if="item.option.value === 0">C</span>
            {{ item.option.label }}
          </div>
          <a-divider style="border-color: #ccc;margin:10px 0" dashed />
        </div>
        <div v-if="proposal !== null">
          <div>10. 其他建议</div>
          <div>{{ proposal }}</div>
        </div>
      </div>
      <div class="right-reply" style="width:50%;">
        <a-textarea v-model:value="reply" placeholder="请输入回复内容" :rows="4" />
        <p class="send-btn">
          <a-button type="primary" @click="setReply">发送</a-button>
        </p>
        <a-divider style="border-color: #ccc;margin:10px 0" />
        <div style="display:flex;" v-for="item in replyList" :key="item.time">
          <div style="width:60px">{{ item.type.label }}：</div>
          <div>
            {{ item.message }}
            <div style="color:#B8B8B8;font-size:14px">{{ item.time }}</div>
          </div>
        </div>
        <a-divider style="border-color: #ccc;margin:10px 0" />
      </div>
    </div>
  </a-drawer>
  <!-- 利润查看 -->
  <a-modal v-model:visible="profitShow" title="利润" width="40%" footer="" @cancel="closeProfit">
    <div>
      <a-table size="small" :columns="[sortColumn, ...columnsProfit]" :rowKey="(record, index) => { return index }" bordered :data-source="profitList" :pagination="profitPagination" @change="profitTableChange" :row-key="record => record.orderVehicleId" :loading="profitLoading">
        <template #sort="{ index }">
          {{ index + 1 }}
        </template>
        <template #brand="{ record }">
          <div>
            <div>{{ record.brand }}{{ record.model }}</div>
          </div>
        </template>
      </a-table>
    </div>
  </a-modal>
  <!-- 小车在途 -->
  <a-modal v-model:visible="vehicleInTransitShow" title="小车在途" @cancel="cancelVehicle" width="90%" footer="">
    <div>
      <div class="m-b1">
        <a-select show-search v-model:value="selectVal" @change="changeVal" style="width: 200px" :options="customerNameOpt"></a-select>
      </div>
      <a-table size="small" :loading="loadingVehicle" :columns="[sortColumn,...columnsVehicle]" :rowKey="(record, index) => { return index }" bordered :data-source="vehicleInTransitList" :pagination="vehicleInTransitPagination" @change="handleTableChange" :scroll="{y: 600 }">
        <template #sort="{ index }">
          {{index+1}}
        </template>
        <template #transStatus="{ record }">
          <div>
            <span>{{ record.transportType?.label + '：' }}</span>
            <span>{{ record.transStatus?.label }}</span>
          </div>
        </template>
      </a-table>
    </div>
  </a-modal>
  <!-- 装车计划 -->
  <a-modal v-model:visible="vehicleInPlayShow" title="发车计划" @cancel="cancelVehicle" width="90%" footer="">
    <div>
      <div class="m-b1">
        <a-select show-search v-model:value="selectVal" @change="changeVal" style="width: 200px" :options="customerNameOpt"></a-select>
      </div>
      <a-table size="small" :loading="loadingVehicle" :columns="[sortColumn,...columnsPlay]" :rowKey="(record, index) => { return index }" bordered :data-source="vehicleInTransitList" :pagination="vehicleInTransitPagination" @change="handleTableChange" :scroll="{y: 600 }">
        <template #sort="{ index }">
          {{index+1}}
        </template>
        <template #vinNo="{ record }">
          <div>
            <span>{{ record.vinNo}}<span v-show="record.model">-{{ record.model }}</span></span>
          </div>
        </template>
      </a-table>
    </div>
  </a-modal>

  <a-modal v-model:visible="logShow" title="聊天日志" footer='' width="50%">
    <Chat v-if="logShow" :orderId="vehicleDetail.orderId" :orderVehicleId="vehicleDetail.orderVehicleId"></Chat>
  </a-modal>
  <!-- 订单结算信息 -->
  <SettleAccounts :id="detailData.orderId" ref="settleAccounts" :type="1" :customerName="detailData.customerName" />
  <!-- 运输 -->
  <VehicleTable ref="vehicleTable" />
  <!-- 位置 -->
  <VehicleGps ref="vehicleGps" />
  <!-- 车辆图片 -->
  <VehicleImg ref="vehicleImgRef" />
  <!-- 方案 -->
  <ShippingPlan ref="shippingRef" :windowShow="shippingShow" />
  <!-- 拆分订单 -->
  <SplitModal v-if="splitVisible" @splitOk="splitOk" v-model:visible="splitVisible" :splitData="splitData"></SplitModal>
  <!-- 委托 -->
  <InspectionCommission ref="inspectionCommission" />
  <Insurances ref='insurancesRef' />
  <AbnormalTable ref="abnormalTableRef" />
  <!-- 关联小程序 -->
  <a-modal v-model:visible="relatedAppletVisible" title="关联小程序" width="30%" footer="">
    <RelatedApplet ref="relatedAppletRef" :orderId="relatedAppletOrderId" />
  </a-modal>
  <a-modal v-model:visible="virtualNumberVisible" title="订单的虚拟号" width="80%" footer="">
    <VirtualNumber ref="virtualNumberRef" :orderId="virtualNumberOrderId" />
  </a-modal>
  <!-- 支付二维码 -->
  <PayQRC ref="payQRCref" />
  <!-- 小程序码 -->
  <AppleQRC ref="appleQRCref" :detailData="detailData" />
  <a-modal v-model:visible="deliveryVisible" title="交车信息查看" width="40%" footer="">
      <div class="delivery-content">
        <div v-show="!deliveryLoading && deliveryData.orderVehicleId">
          <a-form-item label="到收款金额" v-if="deliveryData.paidAmt > 0">
            <span style="color:red"> {{ deliveryData.paidAmt }}</span>
          </a-form-item>
          <span>收车条：</span>
          <a-image :width="100" style="height:100px" :src="deliveryData.fileUrl" :preview="deliveryData.preview" @click="handlePreview(deliveryData)" />
          <a-row :gutter="[24, 12]">
            <a-col :span="10">
              <span>收款对象：</span>
              {{ deliveryData.deliveryType && deliveryData.deliveryType.label }}
            </a-col>
            <a-col :span="7">
              {{ deliveryData.officeOrgName }}
            </a-col>
            <a-col :span="7" v-if="deliveryData.deliveryOfficeOrg != null">
              {{ deliveryData.deliveryOfficeOrg.label }}
            </a-col>
            <a-col :span="24">
              <span>交车人：</span>
              {{ deliveryData.creatorBy }}
            </a-col>
            <a-col :span="24">
              <span>交车时间：</span>
              {{ deliveryData.createTime }}
            </a-col>
            <a-col :span="24">
              <span>交车说明：</span>
              {{ deliveryData.remark }}
            </a-col>
          </a-row>
        </div>
        <h2 v-show="!deliveryLoading && !deliveryData.orderVehicleId" style="text-align: center">暂无交车条</h2>
        <div v-show="deliveryLoading" style="display:flex;justify-content: center;align-items:center">
          <a-spin size="large" />
        </div>
      </div>
    </a-modal>
</template>

<script>
import { reactive, toRefs, ref, onMounted } from 'vue'
import { message } from 'ant-design-vue'
import { CameraOutlined, StarFilled } from '@ant-design/icons-vue'
import { fileUpload as upload } from '@/api/marketing/modList'
import { documentaryPage, follow, addFollow, vehicleInTransitPage, loadingPalyPage } from '@/api/transport/documentary'
import { QRCurl, QRCpayurl } from '@/api/transport/inspection'
import { orderRemark, orderMessage } from '@/api/transport/orderException'
import { incompleteDetail } from '@/api/transport/truck'
import { useRouter } from 'vue-router'
import { copyOrder } from '@/api/trans/order'
import QrcodeVue from 'qrcode.vue'
import Chat from '@/views/components/chat/index'
import SettleAccounts from '@/views/order/positionInfo/settleAccounts'
import VehicleTable from '@/views/order/positionInfo/carTable.vue'
import VehicleGps from '@/views/order/positionInfo/carGps.vue'
import SplitModal from '@/views/order/positionInfo/splitModal.vue'
import VehicleImg from '@/views/components/vehicleImg'
import ShippingPlan from '@/views/components/shippingPlan'
import ResultFollow from '@/views/components/followOrder'
import InspectionCommission from '@/views/components/inspectionCommission'
import Insurances from '../insurance/insurance'
import CustomerDetail from '@/views/crmManagement/tool/customerDetail/index.vue'
import AbnormalTable from '@/views/components/abnormalTable'
import RelatedApplet from '@/views/order/applet/relatedApplet'
import VirtualNumber from '@/views/order/virtualNumber/orderVirtualNumber'
import PayQRC from '@/views/components/payQRC'
import AppleQRC from '@/views/components/appleQRC'
import { focusAdd, focusCancel } from '@/api/businessCenter/businessCenter'
import { vehicleDeliveryInfo } from '@/api/transport/truck'

export default {
  components: {
    Chat,
    QrcodeVue,
    SettleAccounts,
    VehicleTable,
    VehicleGps,
    VehicleImg,
    ShippingPlan,
    CustomerDetail,
    CameraOutlined,
    StarFilled,
    ResultFollow,
    InspectionCommission,
    Insurances,
    AbnormalTable,
    RelatedApplet,
    VirtualNumber,
    PayQRC,
    AppleQRC,
    SplitModal
  },
  props: {
    type: {
      type: Number,
      default: 1
    },
  },
  setup (props) {
    const vehicleImgRef = ref(null)
    const insurancesRef = ref(null)
    const abnormalTableRef = ref(null)
    const payQRCref = ref(null)
    const appleQRCref = ref(null)
    const relatedAppletRef = ref(null)
    const virtualNumberRef = ref(null)
    const selectVal = ref('全部')
    const loadingVehicle = ref(false)
    const customerNameOpt = ref([])
    const router = useRouter()
    const state = reactive({
      deliveryLoading:false,
      deliveryVisible:false,
      deliveryData:{},
      showType:null,
      vehicleInPlayShow: false,
      splitData: {},
      splitVisible: false,
      loading: false,
      QRC: false,
      appletShow: false,
      QRCloading: false,
      relatedAppletVisible: false,
      virtualNumberVisible: false,
      relatedAppletOrderId: '',
      virtualNumberOrderId: '',
      payUrl: '',
      QRCurl: '',
      proposal: '',
      remarkTime: '',
      titleLabel: '',
      placeholder: '',
      customerId: '',
      QRCcontext: '',
      appletUrl: '',
      appletType: null,
      status: null,
      followLoading: false,
      detailShow: false,
      followShow: false,
      profitLoading: false,
      evaluationShow: false,
      profitShow: false,
      vehicleInTransitShow: false,
      logShow: false,
      shippingShow: false,
      endFollowRef: ref(null),
      settleAccounts: ref(null),
      vehicleTable: ref(null),
      vehicleGps: ref(null),
      shippingRef: ref(null),
      inspectionCommission: ref(null),
      imgFileList: [],
      evaluationList: [],
      startCitys: [],
      endCitys: [],
      replyList: [],
      profitList: [],
      orderSettlementList: [
        { label: '货到付款', value: 1 },
        { label: '起运地付清', value: 2 },
        { label: '预付X到付X', value: 3 },
        { label: '月结', value: 4 },
        { label: '记账', value: 5 }
      ],
      statusList: [
        {
          value: 0,
          label: '保存'
        },
        {
          value: 3,
          label: '已接单'
        },
        {
          value: 4,
          label: '待验车'
        },
        {
          value: 5,
          label: '已验车'
        },
        {
          value: 8,
          label: '待提车'
        },
        {
          value: 11,
          label: '待推送'
        },
        {
          value: 1,
          label: '已推送'
        },
        {
          value: 2,
          label: '运输中'
        },

        {
          value: 6,
          label: '已拒绝'
        },
        {
          value: 7,
          label: '已取消'
        },
        {
          value: 9,
          label: '已完成'
        }
      ],
      followForm: {
        documentaryType: null,
        content: null,
        fileIds: [],
        documentaryTime: null
      },
      mirrorFollow: {},
      searchForm: {
        vinNo: '',
        orderId: '',
        otherMobile: '',
        customerName: '',
        dockingPerson: '',
        salesman: '',
        orgId: null,
        isConfirm: null,
        orderStatus: null,
        isRefund: null,
        orderSettlement: null,
        pushStartTime: null,
        pushEndTime: null,
        settlementStatus: null,
        startAddress: '',
        endAddress: ''
      },
      listData: [],
      vehicleInTransitList: [],
      vehicleInTransitListOriginal: [],
      detailData: {},
      vehicleDetail: {},
      mirrorSearchForm: {},
      columns: [
        {
          title: '车牌车型',
          width: '5%',
          dataIndex: 'brand',
          align: 'center',
          slots: {
            customRender: 'brand'
          }
        },
        {
          title: '跟单详情',
          dataIndex: 'documentaryDetails',
          width: '40%',
          align: 'left',
          slots: {
            customRender: 'documentaryDetails'
          }
        },
        {
          title: '跟单类型',
          dataIndex: 'documentaryType',
          align: 'center',
          slots: {
            customRender: 'documentaryType'
          }
        },
        {
          title: '调度员',
          dataIndex: 'currentShipper',
          align: 'center'
        },
        {
          title: '发运状态',
          dataIndex: 'transStatus',
          align: 'center',
          slots: {
            customRender: 'transStatus'
          }
        },
        {
          title: '发运时效',
          dataIndex: 'despatchTime',
          align: 'center'
        },
        {
          title: '所在大板',
          dataIndex: 'truckNo',
          align: 'center'
        },
        {
          title: '当前城市',
          dataIndex: 'nowCity',
          align: 'center',
          slots: {
            customRender: 'nowCity'
          }
        },
        {
          title: '是否交车',
          dataIndex: 'isDelivery',
          slots: {
            customRender: 'isDelivery'
          },
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operate',
          align: 'center',
          slots: {
            customRender: 'operate'
          }
        }
      ],
      columnsPlay: [
        {
          title: '客户名称',
          key: 'customerName',
          dataIndex: 'customerName'
        },
        {
          title: '订单号',
          key: 'orderId',
          dataIndex: 'orderId'
        },
        {
          title: '车牌车型',
          key: 'vinNo',
          dataIndex: 'vinNo',
          slots: { customRender: 'vinNo' }
        },
        {
          title: '起点(订单的)',
          key: 'startDetails',
          dataIndex: 'startDetails'
        },
        {
          title: '终点(订单的)',
          key: 'endDetails',
          dataIndex: 'endDetails'
        },
        {
          title: '调度员',
          dataIndex: 'dispatcher',
          key: 'dispatcher',
        },
        {
          title: '发运时效',
          key: 'despatchTime',
          dataIndex: 'despatchTime'
        },
        {
          title: '预计发车时间',
          key: 'arrangeLoadTime',
          dataIndex: 'arrangeLoadTime'
        },
      ],
      columnsVehicle: [
        {
          title: '客户名称',
          key: 'customerName',
          dataIndex: 'customerName'
        },
        {
          title: '始发地',
          key: 'startAddress',
          dataIndex: 'startAddress'
        },
        {
          title: '目的地',
          key: 'endAddress',
          dataIndex: 'endAddress'
        },
        {
          title: '车型',
          key: 'model',
          dataIndex: 'model'
        },
        {
          title: '车架/牌',
          key: 'vinNo',
          dataIndex: 'vinNo'
        },
        {
          title: '发运状态',
          dataIndex: 'transStatus',
          align: 'center',
          slots: {
            customRender: 'transStatus'
          }
        },
        {
          title: '当前城市',
          key: 'nowCity',
          dataIndex: 'nowCity'
        },
        {
          title: '更新时间',
          key: 'updateTime',
          dataIndex: 'updateTime'
        },
        {
          title: '上一次位置+时间',
          key: 'lastGps',
          dataIndex: 'lastGps'
        },
        {
          title: '推送时间',
          key: 'pushTime',
          dataIndex: 'pushTime'
        }
      ],
      columnsProfit: [
        {
          title: '车型',
          key: 'model',
          width: '15%',
          slots: {
            customRender: 'brand'
          }
        },
        {
          title: '车牌',
          key: 'vinNo',
          dataIndex: 'vinNo'
        },
        {
          title: '附加费',
          key: 'additionalFeeTotal',
          dataIndex: 'additionalFeeTotal'
        },
        {
          title: '毛利',
          key: 'grossProfit',
          dataIndex: 'grossProfit'
        },
        {
          title: '毛利月份',
          key: 'profitMonth',
          dataIndex: 'profitMonth'
        },
        {
          title: '预计利润',
          key: 'estGrossProfit',
          dataIndex: 'estGrossProfit'
        },
        {
          title: '预计发运成本',
          key: 'estTransportCost',
          dataIndex: 'estTransportCost'
        }
      ],
      sortColumn: { title: '序号', slots: { customRender: 'sort' } },
      followPage: {
        current: 1,
        total: 0,
        pageSizeOptions: ['5', '10', '15', '20'],
        pageSize: 5
      },
      profitPagination: {
        current: 1,
        total: 0,
        pageSizeOptions: ['5', '10', '15', '20'],
        pageSize: 10
      },
      vehicleInTransitPagination: {
        current: 1,
        pageSize: 50,
        pageSizeOptions: ['50', '100', '150', '200'],
        showSizeChanger: true,
        showQuickJumper: true,
        showTotal: (total, range) => `共 ${total} 条`,
        onChange: (page, pageSize) => {
          state.vehicleInTransitPagination.current = page
          state.vehicleInTransitPagination.pageSize = pageSize
        }
      },
      pagination: {
        current: 1,
        total: 0,
        pageSizeOptions: ['10', '15', '20'],
        pageSize: 10
      }
    })
    onMounted(() => {
      state.mirrorSearchForm = JSON.stringify(state.searchForm)
      state.mirrorFollow = JSON.stringify(state.followForm)
      loadData()
    })
    const handlePreview = (item) => {
      item.preview = true
      item.fileUrl = item.fileRealUrl
    }
    const lookDelivery = (record) => {
      state.deliveryLoading = true
      state.deliveryVisible = true
      vehicleDeliveryInfo(record.orderVehicleId).then(res => {
        if (res.code === 10000 && res.data) {
          state.deliveryData = res.data
        }
      }).finally(() => { state.deliveryLoading = false })
    }
    const addFollowNew = (item, type) => {
      if (type === 1) {
        focusAdd({
          focusType: 2,
          objectId: item.orderId
        }).then(res => {
          if (res.code !== 10000) return
          loadData()
          message.success('关注成功')
        })
      } else {
        focusCancel({
          focusType: 2,
          objectId: item.orderId
        }).then(res => {
          if (res.code !== 10000) return
          loadData()
          message.success('取消关注成功')
        })
      }
    }
    const splitOk = () => {
      loadData()
    }
    const isTrue = (num) => {
      let arr = [0, 3, 4, 5, 8, 11]
      return arr.includes(num)
    }
    // 订单拆分
    const splitOrders = (item) => {
      console.log(item);
      state.splitData = item
      state.splitVisible = true
    }
    const cancelVehicle = () => {
      state.vehicleInTransitList = []
      selectVal.value = '全部'
    }
    const changeVal = (val) => {
      if (val == '全部') {
        state.vehicleInTransitList = state.vehicleInTransitListOriginal
        return
      }
      let list = state.vehicleInTransitListOriginal.filter(item => item.customerName == val)
      state.vehicleInTransitList = list
    }
    const lookException = record => {
      abnormalTableRef.value.openWindow(true)
      abnormalTableRef.value.loadData(undefined, record.orderVehicleId)
    }
    const closeDrawer = () => {
      state.detailShow = false
      loadData()
    }
    const lookName = (item) => {
      if (item.customerClass) {
        state.showType = String(record.customerClass.value - 1)
      } else {
        state.showType = '2'
      }
    }
    const closeWindow = () => {
      setTimeout(() => {
        state.endFollowRef.resetData()
      })
    }
    const seeInsurance = (id) => {
      setTimeout(() => {
        insurancesRef.value.insuranceDetail(id, true)
      }, 100)
    }
    const showRelatedApplet = (orderId) => {
      state.relatedAppletOrderId = orderId
      state.relatedAppletVisible = true
      setTimeout(() => {
        relatedAppletRef.value.loadData()
      }, 50)
    }
    const showVirtualNumber = (orderId) => {
      state.virtualNumberOrderId = orderId
      state.virtualNumberVisible = true
      setTimeout(() => {
        virtualNumberRef.value.loadData()
      }, 50)
    }
    const loadData = () => {
      state.loading = true
      documentaryPage({
        ...state.searchForm,
        current: state.pagination.current,
        size: state.pagination.pageSize
      }).then(res => {
        if (res.code === 10000) {
          state.listData = res.data.records
          // isDeliveryCard === 1   orderStatus === 9
          state.listData.forEach(item => {
            if (item.isDeliveryCard && item.isDeliveryCard.value === 1 && item.orderStatus.value === 9) item.show = false
            else item.show = true
          })
          state.pagination.total = res.data.total
        }
      }).finally(() => { state.loading = false })
    }
    const followBtn = item => {
      if (item.isFollow?.value === 0 || !item.isFollow) {
        follow(item.orderId).then(res => {
          if (res.code === 10000) {
            message.success(res.msg)
            loadData()
          }
        })
      } else message.info('当前订单已完结或已读')
    }
    const searchData = () => {
      state.pagination.current = 1
      loadData()
    }
    const resetData = () => {
      state.startCitys = []
      state.endCitys = []
      state.searchForm = JSON.parse(state.mirrorSearchForm)
      state.pagination.current = 1
      loadData()
    }
    const paginationChange = (e) => {
      state.pagination.current = e
      loadData()
    }
    const onShowSizeChange = (e, v) => {
      state.pagination.pageSize = v
      loadData()
    }
    const setStartAddressMethod = (value, selectedOptions) => {
      state.startCitys = value
      if (value.length === 1) {
        state.searchForm.startAddress = selectedOptions[0].label
      } else if (value.length === 2) {
        state.searchForm.startAddress = selectedOptions[0].label + selectedOptions[1].label
      } else if (value.length === 3) {
        state.searchForm.startAddress = selectedOptions[0].label + selectedOptions[1].label + selectedOptions[2].label
      } else {
        state.searchForm.startAddress = null
      }
    }
    const setEndAddressMethod = (value, selectedOptions) => {
      state.endCitys = value
      if (value.length === 1) {
        state.searchForm.endAddress = selectedOptions[0].label
      } else if (value.length === 2) {
        state.searchForm.endAddress = selectedOptions[0].label + selectedOptions[1].label
      } else if (value.length === 3) {
        state.searchForm.endAddress = selectedOptions[0].label + selectedOptions[1].label + selectedOptions[2].label
      } else {
        state.searchForm.endAddress = null
      }
    }
    const routerDetail = item => {
      router.push({
        name: 'orderDetail',
        params: {
          id: item.orderId
        }
      })
    }
    const QRCshow = (item) => {
      state.status = 0
      state.detailData = item
      // state.appletShow = true
      setTimeout(() => {
        appleQRCref.value.appletShow = true
        appleQRCref.value.appletChange()
      }, 50)
    }
    const QRCpayImg = (item) => {
      state.detailData = item
      state.status = 1
      state.QRC = true
      state.QRCloading = true
      // console.log(state.id)
      if (state.QRCpay === '') state.QRCloading = true
      QRCpayurl({
        orderId: item.orderId,
        payType: '1'
      }).then(res => {
        if (res.code === 10000) {
          state.payUrl = res.data.qrCode
          state.QRCcontext = ''
        }
        if (res.code === 10001) {
          state.QRCcontext = res.msg
        }
        // console.log(res)
      }).finally(() => {
        state.QRCloading = false
      })
    }
    const lookOrderSettle = item => {
      state.detailData = item
      state.settleAccounts.onmodalVisible(true)
      state.settleAccounts.loadData(item.orderId)
    }
    const evaluationLook = (item) => {
      state.detailData = item
      state.evaluationShow = true
      orderRemark(item.orderId)
        .then(res => {
          if (res.code === 10000) {
            state.evaluationList = res.data.remarks
            state.proposal = res.data.message
            state.remarkTime = res.data.time
          }
        }).catch(err => console.log(err))
      orderMessage(item.orderId)
        .then(res => {
          if (res.code === 10000) {
            state.replyList = res.data
          }
        }).catch(err => console.log(err))
    }
    const lookProfit = item => {
      if (item) state.detailData = item
      state.profitLoading = true
      incompleteDetail(state.detailData.orderId, {
        current: state.profitPagination.current,
        size: state.profitPagination.pageSize
      }).then(res => {
        if (res.code === 10000) {
          state.profitList = res.data.records
          state.profitPagination.total = res.data.total
        }
      }).finally(() => { state.profitLoading = false })
    }
    //装车计划
    const queryVehicleInTransitShow = (type) => {
      loadingVehicle.value = true
      let req = type == 0 ? vehicleInTransitPage : loadingPalyPage
      let data = type == 0 ? { ...state.searchForm } : {}
      if (type == 0) {
        state.vehicleInTransitShow = true
      } else {
        state.vehicleInPlayShow = true
      }
      req(data).then(res => {
        if (res.code === 10000) {
          let nameList = [
            {
              label: '全部',
              value: '全部'
            }
          ]
          res.data.forEach(item => {
            nameList.push(
              {
                label: item.customerName,
                value: item.customerName
              }
            )
          })
          customerNameOpt.value = uniqueBy(nameList, item => item.value)
          state.vehicleInTransitListOriginal = res.data
          state.vehicleInTransitList = state.vehicleInTransitListOriginal
        }
      }).finally(() => {
        loadingVehicle.value = false
      })
    }

    const uniqueBy = (arr, key) => {
      const seen = new Set();
      return arr.filter((item) => {
        const k = key(item);
        return seen.has(k) ? false : seen.add(k);
      });
    };
    const profitTableChange = page => {
      state.profitList = []
      state.profitPagination = page
      lookProfit()
    }
    const closeProfit = () => {
      state.profitList = []
      state.profitPagination.current = 1
      state.profitPagination.total = 0
    }
    const followTypeChange = e => {
      console.log(e);
      switch (e) {
        case 1:
          state.placeholder = '输入跟进内容'
          break
        case 2:
          state.placeholder = '情况描述'
          state.titleLabel = '异常'
          break
        case 3:
          state.placeholder = '问题描述'
          state.titleLabel = '质损'
          break
        case 4:
          state.placeholder = '问题描述'
          state.titleLabel = '异常'
          break
      }
    }
    const beforeUpload = file => {
      state.imgFileList = [...state.imgFileList, file]
      return false
    }
    const handleRemove = file => {
      const index = state.imgFileList.indexOf(file)
      const newFileList = state.imgFileList.slice()
      newFileList.splice(index, 1)
      state.imgFileList = newFileList
      state.followForm.fileIds.forEach((item, index) => {
        if (item.name === file.originFileObj.name) {
          state.followForm.fileIds.splice(index, 1)
        }
      })
    }
    const saveImgs = async () => {
      state.followLoading = true
      state.imgFileList.forEach(async file => {
        requireAPI(file)
      })
      function requireAPI (item) {
        const formData = new FormData()
        formData.append('file', item.originFileObj)
        upload(formData).then(res => {
          if (res.code === 10000) {
            message.success(res.msg)
            state.followForm.fileIds.push(res.data)
          }
        }).finally(() => { state.followLoading = false })
      }
    }
    const confirmFollow = () => {
      if (state.followForm.documentaryType !== 1) {
        if (!state.followForm.documentaryTime) {
          message.error('请选择异常时间')
          return
        }
      }

      if (state.followForm.documentaryType === 3) {
        if (state.followForm.fileIds.length > 0) {
          requireAPI()
          // const formData = new FormData()
          // state.imgFileList.forEach(file => {
          //   formData.append('file', file.originFileObj)
          // })
          // upload(formData).then(res => {
          //   if (res.code === 10000) {
          //     state.followForm.fileIds.push(res.data.id)
          //   }
          // })
        } else message.error('请选择上传质损照片')
      } else {
        if (!state.followForm.content) {
          message.error('请输入问题描述')
        } else {
          requireAPI()
        }
      }
      function requireAPI () {
        state.followLoading = true
        const arr = []
        state.followForm.fileIds.forEach(item => {
          arr.push(item.id)
        })
        state.followForm.fileIds = arr
        setTimeout(() => {
          addFollow({
            ...state.followForm,
            orderId: state.vehicleDetail.orderId,
            orderVehicleId: state.vehicleDetail.orderVehicleId
          }).then(res => {
            if (res.code === 10000) {
              message.success(res.msg)
              resetFollow()
              followOrder()
              loadData()
            }
          }).finally(() => { state.followLoading = false })
        }, 50)
      }
    }
    const resetFollow = () => {
      state.followForm = JSON.parse(state.mirrorFollow)
      state.imgFileList = []
    }
    const onLog = record => {
      state.logShow = true
      state.vehicleDetail = record
    }
    const checkOut = (id) => {
      state.vehicleTable.onvisible(true)
      state.vehicleTable.onorderVehicleId(id)
    }
    const orderVehicleGps = (id) => {
      state.vehicleGps.onvisible(true)
      state.vehicleGps.onorderVehicleId(id)
    }
    const lookVehImg = (orderVehicleId, vinNo) => {
      setTimeout(() => {
        vehicleImgRef.value.vehicleImg(orderVehicleId, vinNo)
      }, 50)
    }
    const lookPlan = record => {
      state.vehicleDetail = record
      state.shippingShow = true
      state.shippingRef.loadData(record.orderId, record.orderVehicleId)
    }
    const inspectionCommissionClick = orderVehicleId => {
      state.inspectionCommission.onVisible(true)
      state.inspectionCommission.onInspectionCommission(orderVehicleId)
    }
    const documentaryTimeChange = (e, v) => {
      state.followForm.documentaryTime = v
    }
    const followOrder = record => {
      if (record) state.vehicleDetail = record
      state.followShow = true
      setTimeout(() => {
        if (state.endFollowRef) state.endFollowRef.loadData(state.vehicleDetail.orderVehicleId)
      })
    }
    const startTimeChange = (e, v) => {
      state.searchForm.pushStartTime = v
    }
    const endTimeChange = (e, v) => {
      state.searchForm.pushEndTime = v
    }

    const copyOrderById = id => {
      copyOrder(id).then(res => {
        if (res.code === 10000) {
          message.success('复制成功，新的订单ID为：' + res.data)
          loadData()
        }
      })
    }
    const openPayQRC = (item) => {
      state.detailData = item
      let obj = {
        startAddress: item.startAddress,
        endAddress: item.endAddress,
        ovId: item.orderVehicleId || null,
        orderId: item.orderId,
        vehicleList: item.vehicleList
      }
      setTimeout(() => {
        payQRCref.value.openWindow(obj)
      }, 10)
    }
    return {
      ...toRefs(state),
      insurancesRef,
      abnormalTableRef,
      relatedAppletRef,
      virtualNumberRef,
      vehicleImgRef,
      appleQRCref,
      payQRCref,
      searchData,
      resetData,
      QRCshow,
      onLog,
      openPayQRC,
      QRCpayImg,
      copyOrderById,
      lookException,
      closeProfit,
      closeDrawer,
      startTimeChange,
      endTimeChange,
      profitTableChange,
      saveImgs,
      seeInsurance,
      showRelatedApplet,
      showVirtualNumber,
      closeWindow,
      followOrder,
      lookPlan,
      inspectionCommissionClick,
      followBtn,
      resetFollow,
      checkOut,
      lookVehImg,
      lookProfit,
      queryVehicleInTransitShow,
      beforeUpload,
      handleRemove,
      confirmFollow,
      followTypeChange,
      orderVehicleGps,
      evaluationLook,
      routerDetail,
      documentaryTimeChange,
      paginationChange,
      onShowSizeChange,
      lookOrderSettle,
      setStartAddressMethod,
      setEndAddressMethod,
      selectVal,
      customerNameOpt,
      changeVal,
      cancelVehicle,
      loadingVehicle,
      splitOrders,
      splitOk,
      isTrue,
      addFollowNew,
      lookName,
      lookDelivery,
      handlePreview
    }
  }
}
</script>

<style lang="less" scoped>
@import './index.less';
</style>
